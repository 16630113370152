<script>
import Capitalize from '../lib/capitalize.js';
import Communities from './registration/community.vue';

export default {
  name: 'RegisterForm',
  components: { Communities },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      responsability: '',
      password: '',
      repeat_password: '',
      isActive: false,
      assistance: [],
      regions: [],
      assistance_id: '',
      region_id: '',
      community_id: ''
      ,
    };
  },
  methods: {
    submit() {
      if (!this.isValid()) return;

      const data = {
        name: this.name,
        email: this.email,
        phone: this.formatPhoneNumber(this.phone),
        responsability: this.responsability,
        password: this.password,
        isActive: false,
        assistance_id: this.assistance_id,
        region_id: this.region_id,
        community_id: this.communityId,
      };

      this.onRegister(data);
    },

    isValid() {
      return this.passwordsMatch() &&
        this.validatePassword(this.password) &&
        this.validatePhoneNumber(this.phone) &&
        this.validateEmail(this.email);
    },

    passwordsMatch() {
      if (this.password !== this.repeat_password) {
        alert('Паролі не співпадають');
        return false;
      }
      return true;
    },

    validatePassword(password) {
      const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      if (!regex.test(password) || password.length < 8 || password.length > 10) {
        alert('Пароль має містити від 8 до 10 символів і включати принаймні одну велику літеру, одну малу літеру, одну цифру і один спеціальний символ.');
        return false;
      }
      return true;
    },

    validatePhoneNumber(phone) {
      const regex = /^\d{2}\(0[5-9]\d\)\d{3}-\d{2}-\d{2}$/;
      if (!regex.test(phone)) {
        alert('Некоректний номер телефону');
        return false;
      }
      return true;
    },

    validateEmail(email) {
      const regex = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
      if (!regex.test(email)) {
        alert('Некоректний email');
        return false;
      }
      return true;
    },

    async onRegister(data) {
      try {
        const response = await fetch(`/api/auth/registration`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          this.user = await response.json();
          alert('Успішна реєстрація!\n Очікується активація.');
          this.resetForm();
        } else {
          const errorData = await response.json();
          alert(errorData.message);
        }
      } catch (err) {
        console.log(err);
      }
    },

    resetForm() {
      setTimeout(() => {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.responsability = '';
        this.password = '';
        this.repeat_password = '';
        this.assistance_id = '';
        this.region_id = '';
        this.community_id = '';
      }, 5000);
    },

    async getAssistance() {
      try {
        const res = await fetch(`/api/assistance`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        if (res.ok) {
          this.assistance = await res.json();
          console.log(this.assistance);
          return this.assistance;
        } else {
          alert('Помилка');
        }
      } catch (err) {
        console.error(err);
      }
    },
    filterInput(input) {
      const regex = /^[а-яА-ЯёЁІі\s]+$/;
      this.name = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },

    filterText(input) {
      const regex = /^[!?.,а-яА-ЯёЁІі\s]+$/;
      this.description = Capitalize(input.split('').filter(char => regex.test(char)).join(''));
    },
    formatPhoneNumber(phone) {
      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },
    onCommunitySelected(value) {
      this.region_id = value;
    },
  },
  mounted() { }
};
</script>



<template>
  <div class="pt-10 px-4">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto h-10 w-auto"
        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
        alt="Enough"
      />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Реєстрація</h2>
    </div>
    <form
      class="max-w-md mx-auto"
      @submit.prevent="submit"
    >
      <div class="grid md:grid-cols-2 gap-x-2 gap-y-4">
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="floating_first_name"
            v-model="name"
            @keyup="filterInput(name)"
            id="floating_first_name"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="floating_first_name"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Ім`я*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="tel"
            v-mask="'38(0##)###-##-##'"
            placeholder=" "
            required
            v-model="phone"
            id="floating_telephone"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            for="floating_telephone"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Телефон*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="email"
            name="floating_email"
            v-model="email"
            id="floating_email"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="floating_email"
            class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Email*</label>
        </div>
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="responsability"
            v-model="responsability"
            @keyup="filterText(name)"
            id="responsability"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="responsability"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Посада*</label>
        </div>
        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />
        <div class="col-span-2 relative z-0 w-full mb-5 group">
          <select
            v-model="assistance_id"
            @click="getAssistance"
            @change="getAssistance"
            id="assistance"
            class="cursor-pointer block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          >
            <option
              selected
              disabled
            >Оберіть питання пілкування</option>
            <option
              v-for="item in assistance"
              :key="item.id"
              :value="item.id"
            >{{ item.description }}</option>
          </select>
          <label
            for="countries"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Оберіть питання пілкування</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="password"
            name="floating_password"
            v-model="password"
            @change="formatPassword(password)"
            id="floating_password"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="floating_password"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Пароль*</label>
        </div>
        <div class="col-span-2 md:col-span-1 relative z-0 w-full mb-5 group">
          <input
            type="password"
            name="repeat_password"
            v-model="repeat_password"
            id="floating_repeat_password"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="floating_repeat_password"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Підтвердіть пароль*</label>
        </div>
        <div class="col-span-2 relative w-full pt-6 group">
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 p-3 md:p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >Відправити</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
label {
  opacity: 0.6;
}
</style>
