<script>

import createLink from '@/lib/createLink.js';
import googleCalendarLink from '@/lib/googleCalendarLink';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import refresh from '@/lib/refreshTokin';
import processingTime from '@/lib/processingTime';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  data() {
    return {
      id: 1,
      appeals: [
      ],
      refreshToken: '',
      timeWorks: '',
      fromDate: '',//'2024-09-03T15:43:53Z',
      toDate: '',//'2024-09-05T15:43:53Z',
      community: '',//'Вовчанська',
      titles: [
        'ID',
        'Дата надходження',
        'Ім\'я телефон',
        'Дата/час',
        'Месанжер',
        'Тип допомоги',
        'Громада',
        'Допомога дитині',
        'Статус',
        'Термін виконання',
        'Виконавець',
        'Подія'
      ]
    }
  },
  methods: {
    async getAppeals() {
      try {

        const response = await fetchWithAuth(`/api/appeal-history/appeal/${this.id}`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeals = await response.json();
          console.log(this.appeals);

        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    processing(ms) {
      return this.timeWorks = processingTime(ms)
    },

    onCuurenPageClick(value) {
      this.currentPage = value;
      this.getComunitys(value)
    },
    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },

    setLink(str, phone, className) {
      return createLink(str, phone, className)
    },
    googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime) {

      const start = startDateTime.replace(/-|:|\.\d+/g, '');
      const end = endDateTime.replace(/-|:|\.\d+/g, '');
      return googleCalendarLink(eventTitle, eventLocation, eventDescription, start, end);
    },
    onOpenForm(id) {
      this.$router.push(`/admin/appeal-history/edit/${id}`)
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      const segments = window.location.href.split('/');
      this.id = segments.pop();
      this.getAppeals();
    })

  },
  watch: {
  }
}

</script>
<template>
  <div>
    <div class="wrapper-page mb-2">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-[13px] text-left rtl:text-right text-gray-500">
          <thead class="text-white bg-gray-400">
            <tr class="">
              <th
                v-for="item of titles"
                :key="item.id"
                scope="col"
                class="text-center px-3 py-2"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in appeals"
              :key="item.id"
              class="cursor-pointer border-b hover:bg-gray-50"
              :class="item.appealStatus.id ===4 ? 'bg-green-50 px-[-1]' : item.appealStatus.id != 2 ?'bg-rose-50 px-[-1]':'bg-sky-50 px-[-1]'"
            >
              <td class="px-2 py-1">
                {{ item.appealId }}
              </td>
              <td class="text-center px-2 py-1">
                {{ onDateFormate(item.createdAt) }}
              </td>

              <td class="px-2 py-1">
                <p>{{ item.appeal.name }}</p>
                <a
                  class="text-sky-600 hover:underline"
                  :href="`tel:+${item.appeal.phone}`"
                >{{ item.appeal.phone }}</a>
              </td>

              <td class="px-2 py-1">
                <p>{{ onDateFormate(item.appeal.date_communication) }}</p>
                <p class="text-center">{{ onTimeFormate(item.appeal.date_communication) }}</p>
              </td>
              <td
                class="px-2 py-1"
                v-html="setLink(item.appeal.communiction, item.appeal.phone, className =`text-sky-600 hover:underline`)"
              >
              </td>
              <td class="px-2 py-1">
                {{ item.AssistanceTypes.description }}
              </td>
              <!-- <td class="px-2 py-1">
                {{ item.description }}
              </td> -->
              <td class="px-2 py-1">
                {{ item.appeal.community.name}}
              </td>
              <td class="text-center px-2 py-1">
                <input
                  type="checkbox"
                  :checked="item.appeal.children_help"
                  class="rounded border-sky-400 text-sky-300 focus:ring-white"
                  disabled
                >
              </td>

              <td class="px-2 py-1">
                {{ item.appealStatus.title }}
              </td>
              <td class="pr-3 py-1 text-right">
                {{processing(item.prosessing_time)}}
              </td>
              <td class="pr-3 py-1 text-right">
                <p>{{item.user.name  }}</p>
                <p>{{item.user.responsability  }}</p>
              </td>
              <td class="px-1 py-2">
                <p class="flex justify-end"><button
                    @click="onOpenForm(item.id)"
                    class="text-white bg-[#1da1f2] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                  ><svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg></button></p>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  height: 70dvh;
}
</style>