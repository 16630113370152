<script>
import Capitalize from '../../lib/capitalize'
export default {
  name: 'Communities',
  props: {
    "value": String
  },
  data() {
    return {
      name: '',
      region_id: '',
      region: '',
      adminCenter: '',
      isVisible: true,
      communities: []
    }
  },
  methods: {
    async getCommunities(name) {
      const response = await fetch(`/api/communities/name`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ name: name }),
      });

      try {
        if (response.ok) {
          this.communities = await response.json();
        }
      } catch (err) {
        console.err(err);

      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄʼ\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },
    onCapitalize(word) {
      return Capitalize(word);
    },
    onClose() {
      this.isVisible = false;
    },
    onSelected(id, name, region_id, region, adminCenter) {
      this.isVisible = false;
      this.$emit('input', id);
      this.name = name;
      this.$emit('selected', region_id);
      this.region = region;
      this.adminCenter = adminCenter;
    },
    setFocus() {
      this.$refs.communityList.focus()
    }
  },
  mounted() {
  }
}
</script>

<template>
  <div class="col-span-2 relative z-50 w-full mb-5">
    <div class="col-span-2 relative z-50 w-full group">
      <input
        id="community"
        ref="community"
        @focus="isVisible = true"
        @keyup="filterInput(name);getCommunities(onCapitalize(name))"
        @click="isVisible = true; getCommunities(name)"
        @change="name=='' ? region='': region"
        @keydown.down="setFocus"
        placeholder=" "
        type="text"
        v-model="name"
        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
      />
      <label
        for="community"
        class="peer-focus:font-medium absolute text-sm text-gray-400  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
      >Громада*</label>
    </div>
    <div
      class="list absolute z-[1] max-h-[300px] md:min-w-[448px] bg-white overflow-auto"
      @mouseout="onClose"
      ref="communityList"
    >
      <div
        v-for="item of communities"
        :key="item.id"
        class="flex  w-full bg-white focus:bg-gray-100 text-sm"
        @mouseenter="isVisible = true"
        @keydown.enter="onSelected(item.id, item.name, item.region_id, item.region)"
        tabindex="0"
      >
        <p
          class="text-gray-400 leading-tight cursor-pointer px-3 py-2 w-full  hover:bg-gray-100 md:max-w-[670px]"
          :class="isVisible ? '' : 'hidden'"
          @click="onSelected(item.id, item.name,item.region_id, item.region, item.adminCenter)"
        >{{ `${item.name}  ${item.adminCenter} ${item.region} область ${item.district} р-н`}} </p>
      </div>
    </div>
    <div
      class="grid md:grid-cols-2 gap-2 mb-5 text-[12px]"
      v-if="region"
    >
      <div class="col-span-2 md:col-span-1 pt-3 pb-2 border-0 border-b-2 border-gray-300 relative z-0 w-full group">{{ region ? `${region} обл.` :''}}</div>
      <div class="col-span-2 md:col-span-1 pt-3 pb-2 border-0 border-b-2 border-gray-300 relative z-0 w-full group">{{ region ? `${adminCenter}` :''}}</div>
    </div>
    <!-- <span class="text-xs text-sky-600 pl-3">{{ region ? `${region} обл.   ${adminCenter}` :''}}</span> -->
  </div>
</template>

<style>
.list {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>