<script>
import { mapState } from 'vuex'

import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Footer from './Footer'

export default {
  name: 'Dashboard',
  computed: {
    ...mapState(['sideBarOpen']),
  },
  components: {
    Sidebar,
    Navbar,
    Footer
  }
}
</script>

<template>
  <div
    class="leading-normal tracking-normal"
    id="main-body"
  >
    <div class="flex flex-wrap">
      <Sidebar />
      <div
        class="w-full bg-gray-50 pl-0 lg:pl-64 min-h-screen overflow-auto"
        :class="sideBarOpen ? 'overlay' : ''"
        id="main-content"
      >
        <Navbar />
        <div class=" m-4 bg-gray-50">
          <transition name="fade">
            <router-view />
          </transition>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
