
<script >
import Capitalize from '../../lib/capitalize'
import Communities from '../appeal/_community.vue';
import Messanger from '../appeal/_messanger.vue';
import Assistance from '../appeal/_assistance.vue';
import DateTime from '../appeal/_datatime.vue';
import ModalInfoVue from '../ModalInfo.vue';

export default {
  components: { Communities, Messanger, Assistance, DateTime, ModalInfoVue },
  data() {
    return {
      name: '',
      community_id: '',
      region_id: '',
      phone: '',
      communiction: '',
      assistans_type: '',
      description: '',
      children_help: false,
      date_communication: '',
      creator: '',
      errNumberPhone: false,
      status_id: 1,
      message: '',
    }
  },
  methods: {
    submit() {

      this.data = {
        name: this.name,
        community_id: this.communityId,
        region_id: this.region_id,
        phone: this.formatPhoneNumber(this.phone),
        communiction: this.communiction,
        assistans_type: this.assistans_type,
        description: this.description,
        children_help: this.children_help,
        date_communication: this.date_communication,
        status_id: this.status_id,
        creator: this.name
      };

      this.onValidatePhoneNumber(this.phone);
      if (this.errNumberPhone) return location.assign('/#phone');

      const dataForm = Object.values(this.data);
      for (let el of dataForm) {
        if (el === undefined || el === '')
          return alert(`Помилка\nЗаповніть форму`);
      }
      this.addAppeal(this.data);
    },
    async addAppeal(data) {
      const response = await fetch(`/api/appeals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      try {
        if (response.ok) {
          this.appeal = await response.json();
          this.message = 'Ваше повідомлення успішно надіслано\n';
          this.showModal();
        } else {
          this.appeal = await response.json();
        }
      } catch (err) {
        console.log(err);
      }
    },
    filterInput(input) {
      let filteredInput = '';
      const regex = new RegExp(/^[а-яА-ЯёЁіїґєЄ'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredInput += char;
        }
      }
      this.name = Capitalize(filteredInput);
    },

    filterText(input) {
      let filteredText = '';
      const regex = new RegExp(/^[!?.,а-яА-ЯёЁіїґє'\s]+$/);
      for (let char of input) {
        if (regex.test(char)) {
          filteredText += char;
        }
      }
      this.description = Capitalize(filteredText);
    },

    formatPhoneNumber(phone) {

      const cleanedPhone = phone.replace(/[^\d]/g, '');
      return cleanedPhone;
    },

    onValidatePhoneNumber(phoneNumber) {

      if (phoneNumber.startsWith('+38(00') || phoneNumber.length != 17) {
        return this.errNumberPhone = true;
      } else {
        return this.errNumberPhone = false;
      }
    },

    onMessangerSelected(value) {

      this.communiction = value;
    },
    onAssistanceSelected(value) {

      this.assistans_type = value;
    },
    onCommunitySelected(value) {
      this.region_id = value;
    },
    onDateTimeSelected(value) {

      this.date_communication = value;
    },
    showModal() {
      this.$refs.modalInfo.onOpen();
    },
    hideModal() {
      window.location.assign('/');
    },
    hideListCommunity() {
      this.$refs.community.onClose();
    }
  },
  watch: {
  },
  mounted() {

  }
}
</script>

<template>
  <div class="main px-4 py-12 lg:px-8">
    <ModalInfoVue
      ref="modalInfo"
      :infoText="message"
      @close="hideModal"
    />
    <div
      class="absolute inset-x-0 top-[-10rem] -z-10 overflow-hidden blur-3xl sm:top-[-20rem]"
      aria-hidden="true"
    >
    </div>
    <div class="hidden lg:block lg:text-[200px] xl:text-[275px] enough">#Досить!</div>
    <h1 class="md:hidden text-rose-600 text-center font-semibold text-[32px] mb-6">Скажи #Досить!</h1>
    <div class="warning mx-auto max-w-2xl">
      <h2 class="text-center text-3xl font-bold text-gray-900 sm:text-4xl tracking-widest">Конфіденційна допомога</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600 text-justify"><b class="text-rose-700">ВАЖЛИВО! </b><span class="text-sm">Перед заповненням форми, прочитайте коротку інформацію до неї:</span></p>
      <ul class="text-justify">
        <li class="text-sm pt-4">1. Ми пропонуємо повну <span class="tracking-wide font-[600]">КОНФІДЕНЦІЙНІСТЬ ЗУСТРІЧЕЙ</span> онлайн. Це означає, що ми не будемо збирати ваші персональні дані, крім засобу зв'язку. Ім'я ви можете змінити.</li>
        <li class="text-sm pt-4">2. Онлайн-зустріч буде відбуватися без ввімкненої камери, або шляхом телефонної розмови у вайбер/вотсап/телеграм або ZOOM.</li>
        <li class="text-sm pt-4">3. Телефон ГО Інша Жінка<a
            class="text-[16px] px-2 text-sky-600 hover:underline"
            href="tel:+380501497063"
          >+38(050)149-70-63</a>(можна писати на<a
            class="text-[16px] pl-2 text-sky-600 hover:underline"
            href="viber://chat?number=380501497063"
            title="Viber"
          >Вайбер</a>). У нас немає цілодобової лінії підтримки, працюють виключно волонтери на безкоштовній основі, тому, якщо вам не відповіли на дзвінок, чекайте - ми обов'язково зателефонуємо у відповідь</li>
      </ul>
    </div>
    <form
      action="#"
      method="POST"
      class="mx-auto mt-16 max-w-2xl sm:mt-10"
    >
      <div class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
        <div class="">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Ім'я (можна вигадане)
            </span>
          </label>
          <div class="mt-2.5">
            <input
              type="text"
              v-model="name"
              @keyup="filterInput(name)"
              placeholder="Ім'я"
              required
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Телефон
            </span>
          </label>
          <div class="mt-2.5">
            <input
              v-mask="'+38(0##)###-##-##'"
              placeholder="+38(0XX)XXX-XX-XX"
              v-model="phone"
              id="phone"
              required
              @blur="onValidatePhoneNumber(phone)"
              type="tel"
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
              :class="errNumberPhone ?'border-2 border-rose-500 text-red-600':''"
            />
            <p
              v-if="errNumberPhone"
              class="text-red-500 text-[12px] transition-all duration-200"
            >Помилка в наборі телефона*</p>
          </div>
        </div>

        <Communities
          v-model="communityId"
          @selected="onCommunitySelected"
        />
        <Messanger
          @checked="onMessangerSelected"
          title="Як з вами краще зв'язатися? (можна обрати декілька варіантів)"
        />
        <Assistance
          @checked="onAssistanceSelected"
          title="З чим потрібна допомога? (можна обрати декілька варіантів)"
        />

        <div class="sm:col-span-2 mt-4">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Опишіть вашу проблему
            </span>
          </label>
          <div class="mt-2.5">
            <textarea
              rows="4"
              v-model="description"
              required
              @keyup="filterText(description)"
              placeholder="Як можна коротше опишіть суть вашої проблеми."
              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="sm:col-span-2 mt-4">
          <label class="block">
            <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
              Ваші діти потребують допомоги разом з вами?
            </span>
          </label>
          <div class="mt-2 space-y-4 bg-white p-4">
            <div class="flex items-center gap-x-3">
              <input
                id="no"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="false"
                class=" cursor-pointer h-5 w-5 border-pink-200 text-pink-300 focus:ring-white"
              >
              <label
                for="no"
                class=" cursor-pointer block text-sm font-medium leading-6 text-gray-900"
              >Ні</label>
            </div>
            <div class="flex items-center gap-x-3">
              <input
                id="yes"
                name="children_help"
                v-model="children_help"
                type="radio"
                :value="true"
                class="h-5 w-5 cursor-pointer border-pink-200 text-pink-300 focus:ring-white"
              >
              <label
                for="yes"
                class="cursor-pointer block text-sm font-medium leading-6 text-gray-900"
              >Так</label>
            </div>
          </div>
        </div>

        <DateTime
          @selected="onDateTimeSelected"
          title="Коли вам зручно буде зустрітися онлайн?"
          placeholder="Зазначте дата та час"
        />

        <div class="mt-1 md:col-span-1 ">
          <button
            type="submit"
            @click.prevent="submit"
            class="w-full text-green-700 hover:text-white border border-green-700 bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:hover:text-rose-500 dark:text-white dark:bg-green-600 dark:focus:ring-green-800"
          >#Досить!</button>
        </div>
        <div class="mt-1 md:col-span-1 ">
          <button
            type="button"
            @click="hideModal"
            class="text-gray-900 w-full hover:text-white border border-gray-800 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
          >Очистити форму</button>
        </div>
      </div>
    </form>

  </div>
</template>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
}
.main {
  background-color: rgb(240, 235, 248);
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
.warning {
  /* border: 1px solid #d7d3d3; */
  padding: 20px 10px;
  border-radius: 8px;
}
.enough {
  position: absolute;
  font-size: 275px;
  transform: rotate(-45deg);
  left: 8%;
  top: 40dvh;
  opacity: 0.07;
  letter-spacing: 1rem;
  z-index: 1;
  color: #f27272;
  pointer-events: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: rgb(255, 255, 255);
  }
  100% {
    background-color: rgb(240, 235, 248);
    opacity: 1;
  }
}
</style>