
<script>
import AppealEdit from '../components/appeal/_edit.vue'
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  name: 'appealHistoryEdit',
  components: { Breadcrumb, AppealEdit }
}
</script>

<template>
  <div class="overflow-auto">
    <Breadcrumb
      title="Звернення"
      link="#"
    />
    <AppealEdit />
  </div>
</template>