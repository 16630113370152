
<script>
// import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      userName: ''
    };
  },
  methods: {
    // ...mapActions(['setUser']),
    async login() {

      try {
        const response = await fetch('/api/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: this.email, password: this.password }),
          credentials: 'include'
        });

        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem('token', data.token);
          // await this.setUser(this.email);
          location.assign('/admin/home');
        } else {
          const errorData = await response.json();
          alert('Error: ' + errorData.message);
        }
      } catch (error) {
        alert('Login failed');
      }
    },
  }
};
</script>

<template>

  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto h-10 w-auto"
        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
        alt="Your Company"
      />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Вхід</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="login">
        <div class="relative z-0 w-full mb-5 group">
          <input
            id="email"
            v-model="email"
            type="email"
            autocomplete="email"
            required=""
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="floating_email"
            class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Email</label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            id="password"
            v-model="password"
            type="password"
            autocomplete="current-password"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="password"
            class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >Пароль</label>
        </div>

        <div class="mt-8">
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 p-3 md:p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >Sign in</button>
        </div>
      </form>
      <router-link
        to="/register"
        class="flex my-2 justify-end text-gray-400 text-sm"
      >Реєстрація</router-link>
    </div>
  </div>
</template>


