import Dashboard from '@/components/Dashboard';
import DashboardHome from '@/pages/Home';
import Users from '@/pages/Users';
import PublicUsers from '@/pages/PublicUsers';
import Chat from '@/pages/Chat';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Appeal from '@/pages/Appeal';
import AppealEdit from '@/pages/AppealEdit';
import AppealsList from '@/pages/AppealsList';
import AppeaHistorylsList from '@/pages/AppealsHistoryList';
import AppealHistoryLisById from '@/pages/AppealHistoryLisById';
import appealHistoryEdit from '@/pages/AppealHistoryEdit.vue'
import AppealAdd from '@/pages/AppealAdd.vue'
import Community from '@/pages/Community';
import Denied from '@/pages/403.vue';
import NotFound from '@/pages/404.vue';
import Calendar from '@/pages/Calendar.vue';

const routes = [
  // { path: '/', redirect: { name: 'DashboardHome' } },
  {
    path: '/', name: 'Appeal', component: Appeal,
    meta: {
      title: 'Enough | Досить',
    }
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/admin', redirect: { name: 'DashboardHome' } },
  {
    path: '/admin', component: Dashboard, children: [
      { path: 'home', name: 'DashboardHome', component: DashboardHome, meta: { auth: true } },
      {
        path: 'public-users', name: 'PublicUsers',
        component: PublicUsers,
        meta: {
          auth: true,
          admin: true
        },
      },
      {
        path: 'appeals-history', name: 'AppealsHistorylsList',
        component: AppeaHistorylsList,
        meta: { auth: true, admin: true, user: true },
      },
      {
        path: 'appeal-add', name: 'AppealAdd',
        component: AppealAdd,
        meta: { auth: true, admin: true },
      },
      {
        path: 'appeal/edit/:id', name: 'AppealAdd',
        component: AppealEdit,
        meta: { auth: true, admin: true }, AppealEdit
      },
      {
        path: 'appeal-history/edit/:id', name: 'appealHistoryEdit',
        component: appealHistoryEdit,
        meta: { auth: true, admin: true, user: true },
      },
      {
        path: 'appeals', name: 'AppealsList',
        component: AppealsList,
        meta: { auth: true, admin: true },
      },
      {
        path: 'communities', name: 'Community',
        component: Community,
        meta: { auth: true, admin: true },
      },
      {
        path: 'chat', name: 'Chat', component: Chat,
        meta: { auth: true, admin: true },
      },
      {
        path: 'denied', name: '403', component: Denied,
        meta: { title: 'Enough | 403', },
      },
      {
        path: 'not-found', name: '404', component: NotFound,
        meta: { title: 'Enough | 404', },
      },
      {
        path: 'calendar', name: 'Calendar', component: Calendar,
        meta: { auth: true, admin: true },
      },
      {
        path: 'appeals-history/appeal/:id', name: 'AppealsHistoryById',
        component: AppealHistoryLisById,
        meta: { auth: true, admin: true },
      }
    ]
  },
  {
    path: '/admin/settings', component: Dashboard, meta: {
      auth: true,
      admin: true,
      user: false
    },
    children: [
      {
        path: 'users', name: 'Users',
        component: Users,
      },
      {
        path: 'appeals', name: 'AppealsList',
        component: AppealsList,
        meta: { auth: true, admin: true },
      },
      {
        path: 'communities', name: 'Community',
        component: Community,
        meta: { auth: true, admin: true },
      },
    ]
  }
]
export default routes;