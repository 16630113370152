let API;
if (process.env.NODE_ENV === "development") {
  API = {
    PORT: 5000,
    HOST: '127.0.0.1',
    SOCKET_PORT: 6001,
  };
} else {
  API = {
    PORT: 5000,
    HOST: '127.0.0.1',
    SOCKET_PORT: 5002,
  };
}

export default API;