import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sideBarOpen: false,
        // user: {}
    },
    getters: {
        sideBarOpen: state => state.sideBarOpen,
        // getUser: state => state.user
    },
    mutations: {
        toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
        // setUser(state, user) {
        //     state.user = user
        // },
        // distroy(state) {
        //     state.user = {}
        // }
    },
    actions: {
        toggleSidebar({ commit }) {
            commit('toggleSidebar')
        },
        // async setUser({ commit }) {
        //     // const url = `/api/users/${email}`;
        //     try {
        //         // const response = await fetch(url, {
        //         //     headers: {
        //         //         'Content-Type': 'application/json',
        //         //         'Authorization': `Bearer ${localStorage.getItem('token')}`
        //         //     }
        //         // });
        //         const user = JSON.parse(localStorage.getItem('user'));
        //         commit('setUser', user);
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }
    }
})
