<template>
  <div
    id="popup-modal"
    tabindex="-1"
    :class="isVisible ? '' : 'opacity-0 pointer-events-none'"
    class="fixed transition-opacity bg-[#6966666e] top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0  h-[100dvh]"
  >
    <div class="relative shadow-lg left-[0] px-4 sm:left-[calc(50dvw-225px)] top-[30dvh] w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow">
        <button
          type="button"
          @click="onClose"
          class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          data-modal-hide="popup-modal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="p-4 md:p-5 text-center">
          <!-- <svg
            class="mx-auto mb-4 text-gray-400 w-12 h-12 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg> -->
          <img
            :src="logo"
            alt=""
          >
          <h3 class="mb-5 text-lg font-normal text-gray-500 ">{{ infoText }}</h3>
          <button
            data-modal-hide="popup-modal"
            type="button"
            @click="onClose"
            class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
          >Закрити</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    'infoText': String,
    'logo': {
      type: String,
      default: () => require('../../src/assets/done.svg')
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    onClose() {
      this.isVisible = false;
      this.$emit('close');
    },
    onOpen() {
      this.isVisible = true;
    }
  }
}
</script>