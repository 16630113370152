<script>

import Pagination from '../Pagination.vue';
import createLink from '@/lib/createLink.js';
import googleCalendarLink from '@/lib/googleCalendarLink';
import { DateFormate, TimeFormate } from '@/lib/dateTime.js';
import refresh from '@/lib/refreshTokin';
import processingTime from '@/lib/processingTime';
import fetchWithAuth from '@/lib/fetchWithAuth';

export default {
  components: { Pagination },
  data() {
    return {
      appeals: [
      ],
      refreshToken: '',
      timeWorks: '',
      titles: [
        '№',
        'Дата надходження',
        'Ім\'я телефон',
        'Месанжер',
        // 'Опис проблеми',
        'Тип допомоги',
        'Місце перебування',
        'Допомога дитині',
        'Дата/час',
        'Статус',
        'Термін виконання',
        'Подія'
      ]
    }
  },
  methods: {
    async getAppeals() {
      try {

        const response = await fetchWithAuth(`/api/appeals/all`, {
          method: 'GET',
        });
        if (response.ok) {
          this.appeals = await response.json();
        } else {
          const res = await response.json();
          if (res.statusCode === 403) {
            this.$router.push('/admin/denied');
          }
        }
      } catch (err) {
        console.log(err);
        location.assign('/login')
      }
    },
    processing(ms) {
      return processingTime(ms)
    },

    onCuurenPageClick(value) {
      this.currentPage = value;
      this.getComunitys(value)
    },
    onDateFormate(date) {
      return DateFormate(date);
    },
    onTimeFormate(time) {
      return TimeFormate(time);
    },

    setLink(str, phone, className) {
      return createLink(str, phone, className)
    },
    googleLink(eventTitle, eventLocation, eventDescription, startDateTime, endDateTime) {

      const start = startDateTime.replace(/-|:|\.\d+/g, '');
      const end = endDateTime.replace(/-|:|\.\d+/g, '');
      return googleCalendarLink(eventTitle, eventLocation, eventDescription, start, end);
    },
    onOpenById(id) {
      this.$router.push(`/admin/appeals-history/appeal/${id}`)
    },
    onOpenForm() {
      this.$router.push('/admin/appeal-add');
    }
  },
  mounted() {
    refresh().then(newToken => {
      sessionStorage.setItem("token", newToken);
      this.getAppeals();
    })

  },
  watch: {
  }
}

</script>
<template>
  <div>
    <div class="wrapper-page mb-2">
      <div class="flex justify-end">
        <button
          class="bg-[#4294ff] hover:bg-blue-600 focus:outline-none rounded-lg mb-3 px-3 py-1 text-white font-semibold shadow"
          @click="onOpenForm"
        >+</button>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-[13px] text-left rtl:text-right text-gray-500">
          <thead class="text-white bg-gray-400">
            <tr class="">
              <th
                v-for="item of titles"
                :key="item.id"
                scope="col"
                class="text-center px-3 py-2"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in appeals"
              :key="item.id"
              class="cursor-pointer border-b hover:bg-gray-50"
              :class="item.status_id == 4 ? 'bg-green-50 px-[-1]' : item.status_id != 2 ?'bg-rose-50 px-[-1]':'bg-sky-50 px-[-1]'"
            >
              <td class="px-2 py-1">
                {{ item.id }}
              </td>
              <td class="text-center px-2 py-1">
                {{ onDateFormate(item.createdAt) }}
                <!-- <p>{{ onTimeFormate(item.createdAt) }}</p> -->
              </td>
              <td class="px-2 py-1">
                <p>{{ item.name }}</p>
                <a
                  class="text-sky-600 hover:underline"
                  :href="`tel:+${item.phone}`"
                >{{ item.phone }}</a>
              </td>
              <td class="px-2 py-1">
                {{ item.communiction }}
              </td>
              <td class="px-2 py-1 max-w-[280px] min-w-[250px]">
                {{ item.assistans_type }}
              </td>

              <td class="px-2 py-1">
                <div
                  v-for="community of item.community"
                  :key="community"
                >
                  <p>{{ community }}</p>
                </div>

              </td>
              <td class="text-center px-2 py-1 max-w-[88px]">
                <input
                  type="checkbox"
                  :checked="item.children_help"
                  class="rounded border-sky-400 text-sky-300 focus:ring-white"
                  disabled
                >
              </td>
              <td
                class="px-2 py-1 max-w-[82px]"
                v-if="new Date(item.date_communication) > new Date()"
              >
                <p>{{ onDateFormate(item.date_communication) }}</p>
                <p class="text-center">{{ onTimeFormate(item.date_communication) }}</p>

              </td>
              <td
                class="px-2 py-1"
                v-else
              >
                <p>{{ onDateFormate(item.date_communication) }}</p>
                <p class="text-center">{{ onTimeFormate(item.date_communication) }}</p>
              </td>

              <td class="px-2 py-1">
                <p>{{ item.status.title }}</p>
              </td>
              <td class="pr-3 py-1 text-right max-w-[95px]">
                {{processing(item.prosessing_time)}}
              </td>
              <td class="min-w-[92px]">
                <p class="text-sky-600 hover:underline">
                  <router-link :to="`/admin/appeals-history/appeal/${item.id}`">Перегляд</router-link>
                </p>
                <p class="text-sky-600 hover:underline">
                  <router-link :to="`/admin/appeal/edit/${item.id}`">Редагувати</router-link>
                </p>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      ref="pagination"
      :pages="pages"
      @click="onCuurenPageClick"
    />
  </div>
</template>

<style>
.wrapper-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  height: 70dvh;
}
</style>