<script>

export default {
  props: {
    title: String,
  },
  data() {
    return {
      assistans: [],
      description: '',
      assistans_type: []
    }
  },
  methods: {
    async getAssistans() {
      const response = await fetch('/api/assistance', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      try {
        if (response.ok) {
          this.assistans = await response.json();
        }
      } catch (err) {
        console.log(err);

      }
    },
    onChecked() {
      this.$emit('checked', this.assistans_type.join('\n'));
    }
  },
  mounted() {
    this.getAssistans();
  }
}
</script>

<template>
  <div class="sm:col-span-2 mt-4">
    <legend
      class="block"
      v-if="title"
    >
      <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
        {{ title }}
      </span>
    </legend>
    <div class="px-3.5 py-3.5 mt-2 bg-white border rounded border-stone-200">

      <div
        v-for="item of assistans"
        :key="item.id"
        class="py-2"
      >
        <div class="relative flex gap-x-3">
          <div class="flex h-6 items-center">
            <input
              :id="item.description"
              v-model="assistans_type"
              :value="item.description"
              type="checkbox"
              @change="onChecked"
              class="cursor-pointer h-5 w-5 rounded border-pink-200 text-pink-400 focus:ring-white"
            />
          </div>
          <div class="text-sm leading-6">
            <label
              :for="item.description"
              class="cursor-pointer font-medium text-gray-900"
            >{{ item.description }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>